//import { push } from 'core-js/core/array'
import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '../firebase'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    user: undefined,
    user_information: {},
    user_type: 'partner',
    user_commissions: {},
    user_commissions_list: [],
    user_objects: {},
    selected_object: null,

    admin: {
      store_ids: [],
      stores: {},

      shopify_orders: {},
      shopify_orders_list: [],
      etsy_orders: {},
      etsy_orders_list: [],

      waiting_object_ids: [],
      objects: {},
      object_list: [],
      selected_object: null,
      partner: {},

      server_ids: [],
      servers: {},
      active_server: null
    }
  },
  mutations: {
    set_loading: (state, value) => state.loading = value,
    login: (state, user) => {
      state.user = user;

      db.collection('user-admin').doc(state.user.uid).get()
        .then(doc => {
          state.user_type = doc.data().user_type;

          switch (state.user_type) {
            case 'partner':
              db.collection('user').doc(state.user.uid).collection('commissions').orderBy('timestamp', 'desc').limit(100)
                .onSnapshot(res => {
                  let temp = {};
                  let temp_list = [];
                  res.forEach(doc => {
                    temp[doc.id] = doc.data();
                    temp_list.push(doc.id);
                  })
                  state.user_commissions = temp;
                  state.user_commissions_list = temp_list;
                })

              db.collection('user').doc(state.user.uid).collection('objects')
                .onSnapshot(res => {
                  let temp = {};
                  res.forEach(doc => {
                    temp[doc.id] = doc.data();
                  })
                  state.user_objects = temp;
                })
              break;
            case 'admin':
              db.collection('stores')
                .onSnapshot(res => {
                  let temp_ids = [];
                  let temp_stores = {};
                  res.forEach(doc => {
                    temp_ids.push(doc.id);
                    temp_stores[doc.id] = doc.data();
                  })
                  state.admin.store_ids = temp_ids;
                  state.admin.stores = temp_stores;
                })
              
              db.collection('admin').doc('lists').collection('object-wait-list').get()
                .then(res => {
                  let temp_ids = [];
                  let temp_objects = {};
                  res.forEach(doc => {
                    temp_ids.push(doc.id);
                    temp_objects[doc.id] = doc.data();
                  })
                  state.admin.objects = temp_objects;
                  state.admin.waiting_object_ids = temp_ids;
                })

              db.collection('server')
                .onSnapshot(res => {
                  let temp_ids = [];
                  let temp_servers = {};
                  res.forEach(doc => {
                    temp_ids.push(doc.id);
                    temp_servers[doc.id] = doc.data();
                  })
                  state.admin.servers = temp_servers;
                  state.admin.server_ids = temp_ids;
                })

              db.collection('objects').get()
                .then(res => {
                  let temp_list = [];
                  res.forEach(doc => {
                    temp_list.push(doc.id);
                    state.admin.objects[doc.id] = doc.data();
                  })
                  state.admin.object_list = temp_list;
                })

              db.collection('stores').doc('shopify').collection('orders').get()
                .then(res => {
                  let shopify_list = [];
                  res.forEach(doc => {
                    state.admin.shopify_orders[doc.id] = doc.data();
                    shopify_list.unshift(doc.id);
                  })
                  state.admin.shopify_orders_list = shopify_list;
                })

              db.collection('stores').doc('Etsy').collection('orders').get()
                .then(res => {
                  let etsy_list = [];
                  res.forEach(doc => {
                    state.admin.etsy_orders[doc.id] = doc.data();
                    etsy_list.unshift(doc.id);
                  })
                  console.log(res)
                  state.admin.etsy_orders_list = etsy_list;
                })             
              
              break;
          }

          db.collection('user').doc(state.user.uid)
            .onSnapshot(doc => {
              state.user_information = doc.data();
            })
        })
      
    },
    set_selected_object: (state, object_id) => state.selected_object = object_id,

    admin_open_active_object: async (state, object_id) => {
      state.admin.selected_object = object_id;
      let object = state.admin.objects[object_id];
      state.admin.selected_object_orders = [];

      for (let each of object.partner) {
        let doc = await db.collection('user').doc(each.id).get()
        state.admin.partner[each.id] = doc.data()
      }

      console.log(state.admin.partner)

      router.push('/admin/active-object');
    },

    admin_open_object: (state, object_id) => {
      object_id = object_id.trim();
      console.log(state.admin.objects[object_id].partner_id)
      console.log(object_id)
      db.collection('user').doc(state.admin.objects[object_id].partner_id).collection('objects').doc(object_id).get()
        .then(doc => {
          console.log(doc.data())
          let data = doc.data();
          for (let each in data) {
            state.admin.objects[object_id][each] = data[each];
          }
          state.admin.selected_object = object_id;
          router.push('/admin/object');
        })
    },

    update_admin_object: (state, data) => {
      state.admin.objects[data.object_id] = data.object_data;
    },
    admin_remove_object: (state, object_id) => {
        state.admin.waiting_object_ids.splice(state.admin.waiting_object_ids.indexOf(object_id), 1);
    },

    admin_select_server: (state, server) => {
      state.admin.selected_server = server;
      router.push('/admin/active-server');
    }
  },
  getters: {
    get_uid: state => state.user.uid,
    get_user_information: state => state.user_information,
    get_user_objects: state => {
      return state.user_objects;
    },
    get_selected_object: state => state.selected_object,
    admin_get_selected_object: state => state.admin.selected_object,
    get_object_details: state => object_id => state.user_objects[object_id],
    admin_get_object_details: state => object_id => state.admin.objects[object_id],
    get_all_revenues: () => {
      return {}
    },
    get_ballance: state => state.user_information.ballance.current,
    admin_get_selected_server: state => state.admin.selected_server,

    return_if_value: () => value => value ? value : '-'
  },
  actions: {
  },
  modules: {
  }
})
