import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/partner/Dashboard.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/admin/onlygcode',
    name: 'onlygcode',
    component: () => import('../views/admin/OnlyGcode.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/partner/dashboard',
    name: 'dashboard',
    component: () => import('../views/partner/Dashboard.vue'),
    meta: {
      title: 'Home - Designer'
    }
  },
  {
    path: '/partner/objects',
    name: 'objects',
    component: () => import('../views/partner/Objects.vue'),
    meta: {
      title: 'Objects - Designer'
    }
  },
  {
    path: '/partner/new-object',
    name: 'new object',
    component: () => import('../views/partner/NewObject.vue'),
    meta: {
      title: 'New Object - Designer'
    }
  },
  {
    path: '/partner/object',
    name: 'object',
    component: () => import('../views/partner/Object.vue'),
    meta: {
      title: 'Object - Designer'
    }
  },
  {
    path: '/partner/payments',
    name: 'payments',
    component: () => import('../views/partner/Payments.vue'),
    meta: {
      title: 'Payments - Designer'
    }
  },
  {
    path: '/partner/settings',
    name: 'settings',
    component: () => import('../views/partner/Settings.vue'),
    meta: {
      title: 'Settings - Designer'
    }
  },
  {
    path: '/partner/profile',
    name: 'profile',
    component: () => import('../views/partner/Profile.vue'),
    meta: {
      title: 'Profile - Designer'
    }
  },

  {
    path: '/imprint',
    name: 'imprint',
    component: () => import('../views/Imprint.vue'),
    meta: {
      title: 'Imprint'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue'),
    meta: {
      title: 'Privacy'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Terms.vue'),
    meta: {
      title: 'Terms'
    }
  },
  {
    path: '/admin/active-object',
    name: 'admin-active-object',
    component: () => import('../views/admin/ActiveObject.vue')
  },
  {
    path: '/admin/stores/shopify',
    name: 'admin-stores-etsy',
    component: () => import('../views/admin/stores/Shopify.vue')
  },
  {
    path: '/admin/stores/etsy',
    name: 'admin-stores-etsy',
    component: () => import('../views/admin/stores/Etsy.vue')
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('../views/admin/Dashboard.vue')
  },
  {
    path: '/admin/analytics',
    name: 'admin-analytics',
    component: () => import('../views/admin/Analytics.vue')
  },
  {
    path: '/admin/new-order',
    name: 'admin-new-order',
    component: () => import('../views/admin/NewOrder')
  },
  {
    path: '/admin/financials',
    name: 'admin-financials',
    component: () => import('../views/admin/Financials.vue')
  },
  {
    path: '/admin/objects',
    name: 'admin-objects',
    component: () => import('../views/admin/Objects.vue')
  },
  {
    path: '/admin/orders',
    name: 'admin-orders',
    component: () => import('../views/admin/Orders.vue')
  },
  {
    path: '/admin/object',
    name: 'admin-object',
    component: () => import('../views/admin/Object.vue')
  },
  {
    path: '/admin/profile',
    name: 'admin-profile',
    component: () => import('../views/admin/Profile.vue')
  },
  {
    path: '/admin/servers',
    name: 'admin-servers',
    component: () => import('../views/admin/Servers.vue')
  },
  {
    path: '/admin/stores',
    name: 'admin-stores',
    component: () => import('../views/admin/Stores.vue')
  },
  {
    path: '/admin/modelling',
    name: 'admin-modelling',
    component: () => import('../views/admin/Modelling.vue')
  },
  {
    path: '/admin/labels',
    name: 'admin-labels',
    component: () => import('../views/admin/Labels.vue')
  },
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import('../views/Iframe.vue')
  },
  {
    path: '/admin/active-server',
    name: 'admin-active-server',
    component: () => import('../views/admin/ActiveServer.vue')
  },
  {
    path: '/admin/config',
    name: 'admin-config',
    component: () => import('../views/admin/Config.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
