import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBkG73qPFzIJvMoaxLGWsnjDIKqnXAJiYk",
  authDomain: "rapid-ood.firebaseapp.com",
  projectId: "rapid-ood",
  storageBucket: "rapid-ood.appspot.com",
  messagingSenderId: "249965574796",
  appId: "1:249965574796:web:02b47b39a2ab514a5819a9",
  measurementId: "G-SWLBV8NGFF"
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
  })
  
firebase.firestore().enablePersistence({synchronizeTabs: true})
    .catch(err => {
        console.log(err)
    });

firebase.auth().languageCode = 'de';

var db = firebase.firestore();
var storage = firebase.storage();
var auth = firebase.auth();
var fieldValue = firebase.firestore.FieldValue;


export { db , storage, firebase, auth, fieldValue };